import { Nullable } from '@starsoft/common/interfaces';

export function getMedal(position: number): Nullable<string> {
  switch (position) {
    case 1:
      return 'gold.webp';
    case 2:
      return 'silver.webp';
    case 3:
      return 'copper.webp';
    default:
      null;
  }
}
