import useTranslation from 'next-translate/useTranslation';
import { TablePositionCellProps } from './props';
import styles from './styles.module.scss';
import { Nullable } from '@starsoft/common/interfaces';
import { Image } from '@starsoft/common/components';
import { getMedal } from '@/utils/tournaments/getMedal';

export default function TablePositionCell({
  position,
  hideLabel,
}: TablePositionCellProps) {
  const { t } = useTranslation('data-grid');

  const medal: Nullable<string> = getMedal(position);

  return (
    <div className={`${styles.row} ${styles[`row--${position}-place`]}`}>
      {medal && (
        <Image
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/icons/medal/${medal}`}
          className={styles.row__image}
          width={24}
          height={24}
          alt="medal"
          bundle
        />
      )}
      {/* <div
        className={`${styles.row__badge} ${styles[`row__badge--${position}-place`]}`}
      >
        <div
          className={`${styles.row__badge__position} ${styles[`row__badge--${position}-place`]}`}
        >
          {position}
        </div>
      </div> */}
      {!hideLabel && (
        <p className={styles.row__label}>
          {t('data_grid_place', { place: position })}
        </p>
      )}
    </div>
  );
}
